import * as React from "react";
import { Helmet } from "react-helmet";

import Header from "../templates/header";
import Footer from "../templates/footer";

// markup
const TermsOfService = () => {
  return (
    <div>
      <Helmet>
        <title>Cloto Terms of Use</title>
      </Helmet>
      <Header></Header>
      <main>
        <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div class="max-w-3xl mx-auto">
            <h1 className="text-4xl mt-4 mb-4">Cloto, Inc. Terms of Use</h1>

            <p className="mb-2">February 14th, 2018</p>
            <p className="mb-2">
              These terms and conditions (“User Terms”) set forth apply to visit
              and use of our website at www.cloto.ca (the “Website”), the
              Service and the Application (as defined below), as well as to all
              information, products, services and recommendations provided on or
              through the Website, the Service and the Application.
            </p>
            <p className="mb-2">
              PLEASE READ THESE TERMS OF SERVICE CAREFULLY. BY ACCESSING OR
              USING CLOTO, INC. APPLICATIONS OR ANY SERVICES PROVIDED BY US, YOU
              AGREE TO BE GOVERNED BY THESE TERMS OF SERVICE. IF YOU DO NOT
              AGREE TO ALL OF THESE TERMS AND CONDITIONS, DO NOT ACCESS OR USE
              CLOTO, INC. APPLICATIONS OR ANY PRODUCTS OR SERVICES PROVIDED BY
              CLOTO, INC.
            </p>
            <p className="mb-2">
              Your service provider is only Cloto, Inc., a corporation
              established in the Province of Ontario (“Cloto”).
            </p>

            <h2 className="text-3xl mt-3 mb-3">
              What service(s) does Cloto provide?
            </h2>

            <p className="mb-2">
              Cloto provides both scheduled and/or on-demand pick-up and
              delivery services for laundry and garment donations. Cloto
              performs in-house washing and folding of clothing garments and
              linens that you give Cloto for cleaning. Pick-up and delivery can
              be scheduled through our Website or smartphone mobile application
              (the “Application”). All services provided by Cloto to you by
              means of your use of the Website or Application are hereafter
              referred to as the “Service”.
            </p>

            <h2 className="text-3xl mt-3 mb-3">
              How is an agreement carried out between you and Cloto?
            </h2>

            <p className="mb-2">
              By using the Website, Application or the Service, you enter an
              agreement with Cloto (the “Agreement”). To place orders from the
              Website, Application or Service, you must be a registered Cloto
              user. To register, you must provide Cloto with your personal
              information, mobile telephone number, address for pick-up and
              delivery, and credit card data.
            </p>

            <p className="mb-2">
              You must be of legal age or older to use the Service, Website, or
              Application. If you live in a region that restricts the use of the
              Service, Website, or Application because of age, or restricts you
              to enter agreements like this one due to age, you must follow such
              age limits and you must not use the Service and the Application.
              You represent that if you are an individual, you are of legal age
              to enter into a binding agreement, or that if you are registering
              on behalf of a legal entity, that you are authorized to enter
              into, and bind the entity to, these User Terms and register for
              the Service and the Application.
            </p>

            <h2 className="text-3xl mt-3 mb-3">Content</h2>

            <p className="mb-2">
              All information, data, text, software, music, sound, photographs,
              graphics, video, messages or other materials, whether publicly
              posted or privately transmitted to the Website and Application by
              viewers or users (“User Content”), is the sole responsibility of
              such viewers or users. This means that the viewer or user, and not
              Cloto, are entirely responsible for all such material uploaded,
              posted, emailed, transmitted or otherwise made available by using
              the Service. Cloto does not control or actively monitor User
              Content and, as such, does not guarantee the accuracy, integrity
              or quality of such content. Users acknowledge that by using the
              Service, they may be exposed to materials that are offensive,
              indecent or objectionable. Under no circumstances will Cloto be
              liable in any way for any materials, including, but not limited
              to, for any errors or omissions in any materials or any defects or
              errors in any printing or manufacturing, or for any loss or damage
              of any kind incurred as a result of the viewing or use of any
              materials posted, emailed, transmitted or otherwise made available
              via the Service.
            </p>

            <h3 className="text-2xl mt-3 mb-3">
              Restrictions on User Content and Use of the Service
            </h3>

            <p className="mb-2">
              Cloto reserves the right at all times (but will have no
              obligation) to remove or refuse to distribute any User Content and
              to terminate users or reclaim usernames. We also reserve the right
              to access, read, preserve, and disclose any information as we
              reasonably believe is necessary to (i) satisfy any applicable law,
              regulation, legal process or governmental request, (ii) enforce
              these Terms of Use, including investigation of potential
              violations hereof, (iii) detect, prevent, or otherwise address
              fraud, security or technical issues, (iv) respond to user support
              requests, or (v) protect the rights, property or safety of our
              users and the public.
            </p>

            <p className="mb-2 font-bold">
              In using the Website, Application and/or Service You shall not:
            </p>

            <ul className="list-disc list-outside ml-8 mb-2">
              <li>
                copy any content unless expressly permitted to do so herein;
              </li>
              <li>
                upload, post, email, transmit or otherwise make available any
                material that:is unlawful, harmful, threatening, abusive,
                harassing, tortuous, defamatory, vulgar, obscene, pornographic,
                libelous, invasive of another’s privacy, hateful, or racially or
                ethnically objectionable, encourages criminal behavior, gives
                rise to civil liability, violates any law, or is otherwise
                objectionable; You do not have a right to make available under
                any law or under a contractual relationship; infringes any
                patent, trademark, trade secret, copyright or other proprietary
                rights of any party (including privacy rights); is or contains
                unsolicited or unauthorized advertising, solicitations for
                business, promotional materials, “junk mail,” “spam,” “chain
                letters,” “pyramid schemes,” or any other form of solicitation;
                contains software viruses or any other computer code, files or
                programs designed to interrupt, destroy or limit the
                functionality of any computer software or hardware or
                telecommunications equipment or data or the Website or that of
                any users or viewers of the Website or that compromises a user’s
                privacy; or contains any falsehoods or misrepresentations or
                create an impression that You know is incorrect, misleading, or
                deceptive, or any material that could damage or harm minors in
                any way;
              </li>
              <li>
                impersonate any person or entity or misrepresent their
                affiliation with a person or entity;
              </li>
              <li>
                forge headers or otherwise manipulate identifiers in order to
                disguise the origin of any material transmitted to or through
                the Website or impersonate another person or organization;
              </li>
              <li>
                interfere with or disrupt the Website or servers or networks
                connected to the Website, or disobey any requirements,
                procedures, policies or regulations of networks connected to the
                Website or probe, scan, or test the vulnerability of any system
                or network or breach or circumvent any security or
                authentication measures;
              </li>
              <li>
                intentionally or unintentionally violate any applicable local,
                state, national or international law or regulation;
              </li>
              <li>
                collect or store personal data about other users or viewers;
              </li>
              <li>
                license, sell, rent, lease, transfer, assign, distribute, host,
                or otherwise commercially exploit the Website or Application; or
              </li>
              <li>
                modify, translate, make derivative works of, disassemble,
                decompile, reverse compile or reverse engineer any part of the
                Application or any software provided as part of the Website,
                except to the extent the foregoing restrictions are expressly
                prohibited by applicable law.
              </li>
            </ul>

            <p className="mb-2">
              You also agree not to access the Website or Application in a
              manner that utilizes the resources of the Website or Application
              more heavily than would be the case for an individual person using
              a conventional web browser. Notwithstanding the foregoing,
              operators of public search engines may use spiders or other bots
              for the purpose of creating publicly available searchable indices
              of the materials on this Website.
            </p>

            <h3 className="text-2xl mt-3 mb-3">
              License of Content to Cloto and other Users
            </h3>

            <p className="mb-2">
              By submitting, posting or displaying User Content on or through
              the Service, you grant us (and our agents) a non-exclusive,
              royalty-free license (with the right to sublicense) to use, copy,
              modify, transmit, display and distribute such User Content. Cloto
              will not be responsible or liable for any use of User Content in
              accordance with these Terms of Use. You represent and warrant that
              you have all the rights, power and authority necessary to grant
              the rights granted herein to any User Content that you submit.
            </p>

            <h2 className="text-3xl mt-3 mb-3">End User License</h2>

            <p className="mb-2">
              Except for User Content, the Application, this Website, and the
              information and materials contained therein, are the property of
              Cloto and its licensors, and are protected from unauthorized
              copying and dissemination by copyright law, trademark law, and
              other intellectual property laws. Subject to these Terms of Use,
              Cloto grants you a non-transferable, non-exclusive, license to (a)
              use the Website for your use, and (b) download, install and use
              one copy of the App on a mobile device that you own or control for
              your use (the “License”). The Application is licensed to you and
              not sold. Nothing in the Terms of Use gives you a right to use the
              Cloto names, trademarks, logos, domain names, and other
              distinctive brand features without our prior written consent. You
              shall not attempt to override or circumvent any of the usage rules
              or restrictions on the Website. Any future release, update, or
              other addition to functionality of the Website or Application
              shall be subject to the terms of these Terms of Use.
            </p>

            <h3 className="text-2xl mt-3 mb-3">Payment</h3>
            <p className="mb-2">
              The use of the Website and Application is free of charge. Cloto
              has the right to introduce a fee for the use of the Application.
              If Cloto decides to introduce such a fee, Cloto shall inform you
              accordingly and allow you to either continue or terminate your
              Service Agreement.
            </p>
            <p className="mb-2">
              Cloto offers different subscription plans for your laundry needs
              (each, a “Plan”). You have one free pickup day every week. The
              number of totes that may be picked up on your free pickup day are
              unlimited, however you will be charged on a per tote basis
              anything exceeding your Plan tote limit. If you change your Plan,
              the Plan price will also change accordingly. At any point, you can
              upgrade or downgrade or cancel your Plan, if applicable (i.e. may
              not downgrade from the base Plan). Payment of the Plan is taken
              upon pickup of first order. Extra charges for premium services may
              apply. For more information about Subscription Plans and pricing,
              visit our Pricing page and FAQs for more details.
            </p>
            <p className="mb-2">
              Cloto shall charge you for the services you request on-demand,
              which may include wash and fold laundry, or related services
              (“Cleaning”). You agree that you will pay for all Cleaning you
              purchase through Cloto, and that Cloto may charge your credit card
              account as provided by you when registering for the Service
              (including any taxes, late fees, or additional fees as applicable)
              that may be accrued by or in connection with your account. You are
              responsible for the timely payment of all fees and for providing
              Cloto with a valid credit card account for payment of all fees at
              all times. Any payment made is non-refundable.
            </p>
            <p className="mb-2">
              Cloto may use a third-party payment processor (the “Payment
              Processor”) to link your credit card account to the Application
              and Service. The processing of payments or credits, as applicable,
              in connection with your use of the Website, Application and
              Service will be subject to the terms, conditions and privacy
              policies of the Payment Processor and your credit card issuer in
              addition to these User Terms. Cloto is not responsible for any
              errors by the Payment Processor. In connection with your use of
              the Services, Cloto will obtain certain transaction details, which
              Cloto will use solely in accordance with its Privacy Policy.
            </p>

            <h3 className="text-2xl mt-3 mb-3">Indemnification</h3>
            <p className="mb-2">
              By accepting these Terms of Serivce and using the Application or
              Service, you agree that you shall defend, indemnify and hold
              Cloto, its affiliates, its agents, its vendors, its past, present
              and future employees, its suppliers, its equity holders, its
              representatives, its licensors, and each of their officers,
              directors, other users, attorneys and agents harmless from and
              against any and all claims, costs, damages, losses, liabilities
              and expenses (including attorneys’ fees and costs) arising out of
              or in connection with: (a) your violation or breach of any term of
              these User Terms or any applicable law or regulation, whether or
              not referenced herein; (b) your violation of any rights of any
              third party, or (c) your use or misuse of the Application or
              Service.
            </p>

            <h3 className="text-2xl mt-3 mb-3">Liability</h3>
            <p className="mb-2">
              The information, recommendations and/or services provided to you
              on or through the Website, the Service and the Application is for
              general information purposes only and does not constitute advice.
              Cloto will reasonably keep the Website and the Application and its
              contents correct and up to date but does not guarantee that (the
              contents of) the Website and/or Application are free of errors,
              defects, malware and viruses or that the Website and/or
              Application are correct, up to date and accurate.
            </p>
            <p className="mb-2">
              Cloto shall not be liable for any damages resulting from the use
              of (or inability to use) the Website or Application (but to the
              exclusion of death or personal injury), including damages caused
              by malware, viruses or any incorrectness or incompleteness of the
              Information or the Website or Application, unless such damage is
              the result of any wilful misconduct or from gross negligence on
              the part of Cloto.
            </p>
            <p className="mb-2">
              Cloto shall further not be liable for damages resulting from the
              use of (or the inability to use) electronic means of communication
              with the Website or the Application, including – but not limited
              to – damages resulting from failure or delay in delivery of
              electronic communications, interception or manipulation of
              electronic communications by third parties or by computer programs
              used for electronic communications and transmission of viruses.
            </p>
            <p className="mb-2">
              User understands that there is inherent risk in Cleaning and there
              is potential for clothing and related items to get lost or
              damaged. Cloto will do its best to ensure situations like this do
              not happen, and in the instances they do happen, will work with
              the User to rectify the situation. Without prejudice to the
              foregoing, and insofar as allowed under mandatory applicable law,
              Cloto’s aggregate liability shall in no event exceed an amount of
              $100(CAD) or, where applicable, the equivalent of that amount in
              the currency used by you for the payment for Cleaning.
            </p>

            <h3 className="text-2xl mt-3 mb-3">Disclaimers</h3>
            <p className="mb-2">
              YOU ARE SOLELY RESPONSIBLE FOR, AND ASSUME ALL RISKS RELATED TO,
              THE PROPER SAFETY AND HANDLING, PREPARATION, STORAGE, USE AND
              CONSUMPTION OF THE CLOTHING (HEREBY “PRODUCTS”) YOU RECEIVE FROM
              US. YOU ARE ALSO SOLELY RESPONSIBLE FOR KNOWING ABOUT ANY SKIN
              ALLERGIES OR HEALTH CONDITIONS YOU MAY HAVE AND VERIFYING THE
              PRODUCTS AND THEIR CONTENTS BEFORE HANDLING, USING OR CONSUMING
              SUCH PRODUCTS. FURTHER, YOU UNDERSTAND, ACKNOWLEDGE AND AGREE THAT
              WE WASH PRODUCTS USING DETERGENT INGREDIENTS (I.E. SURFACTANTS)
              THAT MAY IRRITATE SOME USERS’ SKIN. WE CANNOT GUARANTEE THAT
              CROSS-CONTAMINATION WILL NOT OCCUR BETWEEN PRODUCTS, SO WHILE WE
              MAKE EVERY EFFORT TO CARRY OUT YOUR PERSONAL PREFERENCES, THERE IS
              NO GUARANTEE FOR CROSS-CONTAMINATION PREVENTION. WE ARE NOT
              RESPONSIBLE FOR ANY THIRD-PARTY SAMPLES NOR THE CONSUMPTION
              THEREOF OF SUCH SAID SAMPLES — USE AT YOUR OWN DISCRETION.
            </p>
            <p className="mb-2">
              WE ATTEMPT TO DISPLAY THE PRODUCTS AND OTHER MATERIALS AND
              INFORMATION YOU VIEW ON THE SITES, INCLUDING PRICING, ANALYTICS
              AND ENVIRONMENTAL INFORMATION, AS ACCURATELY AS POSSIBLE. HOWEVER,
              WE DO NOT GUARANTEE THE ACCURACY OF SUCH MATERIALS AND
              INFORMATION. IN THE EVENT OF AN ERROR ON OUR SITES, IN AN ORDER
              CONFIRMATION, IN PROCESSING OR DELIVERING AN ORDER OR OTHERWISE,
              WE RESERVE THE RIGHT TO CORRECT SUCH ERROR AND REVISE YOUR ORDER
              ACCORDINGLY (INCLUDING CHARGING THE CORRECT PRICE) OR TO CANCEL
              YOUR ORDER AND ISSUE YOU A REFUND. YOU FURTHER AGREE THAT THE
              PRODUCTS AND OTHER MATERIALS YOU RECEIVE IN YOUR ORDER MAY VARY
              FROM THE PRODUCTS AND MATERIALS DISPLAYED ON THE SITES DUE TO A
              NUMBER OF FACTORS, INCLUDING, WITHOUT LIMITATION, SYSTEM
              CAPABILITIES AND CONSTRAINTS OF YOUR COMPUTER, MANUFACTURING
              PROCESS OR SUPPLY ISSUES, THE AVAILABILITY AND VARIABILITY OF
              PRODUCTS AND LABOR, AND VARIABILITY OF EQUIPMENT AND APPLIANCES.
              THE SITES MAY CONTAIN INFORMATION ABOUT A SERVICE THAT IS NOT
              AVAILABLE IN EVERY LOCATION. A REFERENCE TO PRODUCTS OR A SERVICE
              ON THE SITE DOES NOT IMPLY OR GUARANTEE THAT IT IS OR WILL BE
              AVAILABLE IN YOUR LOCATION OR AT THE TIME OF YOUR ORDER.
            </p>
            <p className="mb-2">
              We reserve the right to change any and all Content and to modify,
              suspend or stop providing access to the Sites (or any features or
              functionality of the Sites) and the Products at any time without
              notice and without obligation or liability to you. Reference to
              any products, services, processes or other information by trade
              name, trademark, manufacturer, supplier, vendor or otherwise does
              not constitute or imply endorsement, sponsorship or recommendation
              thereof, or any affiliation therewith, by us.
            </p>

            <h3 className="text-2xl mt-3 mb-3">
              Apple App Store and Google Play Store Additional Terms and
              Conditions
            </h3>
            <p className="mb-2">
              The following additional terms and conditions apply to you if you
              are using the Application from the Apple App Store and/or Google
              Play Store. To the extent the other terms and conditions of this
              Terms of Use are less restrictive than, or otherwise conflict
              with, the terms and conditions of this Section, the more
              restrictive or conflicting terms and conditions in this Section
              apply, but solely with respect to the Application from the Apple
              App Store and/or Google Play Store. You acknowledge and agree
              that:
            </p>

            <ul className="list-disc list-outside ml-8 mb-2">
              <li>
                these Terms of Use are concluded solely between Cloto and You
                and not with Apple or Google, and Cloto not Apple or Google, is
                solely responsible for the Application and the content thereof.
                To the extent that these Terms of Use provide for usage rules
                for the Application which are less restrictive or in conflict
                with the Apple App Store Terms of Service or Google Play Store
                Terms of Service, the more restrictive or conflicting Apple or
                Google term will take precedence and will apply;
              </li>
              <li>
                Apple and Google have no obligation whatsoever to provide any
                maintenance and support services with respect to the
                Application. Cloto is solely responsible for any product
                warranties, whether express or implied by law, to the extent not
                effectively disclaimed. In the event of any failure of the
                Application to conform to any applicable warranty, you may
                notify Apple or Google, and Apple or Google will refund the
                purchase price for the Application to you and to the maximum
                extent permitted by applicable law, Apple and Google will have
                no other warranty obligation whatsoever with respect to the App,
                and any other claims, losses, liabilities, damages, costs or
                expenses attributable to any failure to conform to any warranty
                will be Cloto’s sole responsibility;
              </li>
              <li>
                Cloto, not Apple and Google, is responsible for addressing any
                claims of you or any third party relating to the App or your
                possession and/or use of the App, including, but not limited to:
                (i) product liability claims; (ii) any claim that the App fails
                to conform to any applicable legal or regulatory requirement;
                (iii) claims arising under consumer protection or similar
                legislation.; and/or (iv) intellectual property infringement
                claims; and
              </li>
              <li>
                Apple and Google, and their subsidiaries, are third party
                beneficiaries of this Agreement, and that, upon your acceptance
                of these Terms of Use, Apple and Google will have the right (and
                will be deemed to have accepted the right) to enforce these
                Terms of Use against you as a third party beneficiary thereof.
              </li>
            </ul>
            <p className="mb-2">
              You represent and warrant that (i) you are not located in a
              country that is subject to a Canada or U.S. Government embargo, or
              that has been designated by the Canada or U.S. Government as a
              “terrorist supporting” country; and (ii) you are not listed on any
              Canada or U.S. Government list of prohibited or restricted
              parties.
            </p>

            <h3 className="text-2xl mt-3 mb-3">Termination</h3>

            <p className="mb-2">
              Cloto may, under certain circumstances and without prior notice,
              immediately terminate Your ability to access the Website or App or
              portions thereof. Cause for such termination shall include, but
              not be limited to, (a) breaches or violations of these Terms of
              Use or any other agreement that You may have with Cloto
              (including, without limitation, non-payment of any fees owed in
              connection with the website or otherwise owed by You to Cloto),
              (b) requests by law enforcement or other government agencies, (c)
              a request by You, (d) discontinuance or material modification to
              the website (or any part thereof), (e) unexpected technical,
              security or legal issues or problems, and/or (f) participation by
              You, directly or indirectly, in fraudulent or illegal activities.
              Termination of Your access to the Website or App may also include
              removal of some or all of the materials uploaded by You. You
              acknowledge and agree that all terminations may be made by Cloto
              in its sole discretion and that Cloto shall not be liable to You
              or any third-party for any termination of Your access to the
              Website or App or for the removal of any of the materials uploaded
              by You to the Website. Any termination of these Terms of Use by
              Cloto shall be in addition to any and all other rights and
              remedies that Cloto may have.
            </p>

            <h3 className="text-2xl mt-3 mb-3">
              Invalidity of one or more provisions
            </h3>

            <p className="mb-2">
              The invalidity of any term of these User Terms shall not affect
              the validity of the other provisions of these User Terms.
            </p>
            <p className="mb-2">
              If and to the extent that any provision of these User Terms is
              invalid, or is unacceptable in the given circumstances according
              to the criteria of reasonableness and fairness, a provision shall
              apply between the parties instead that is acceptable considering
              all the circumstances and which corresponds with the provisions of
              the void part as much as possible, taking into account the content
              and the purpose of these User Terms.
            </p>

            <h3 className="text-2xl mt-3 mb-3">
              Modification of the Service and User Terms
            </h3>

            <p className="mb-2">
              Cloto reserves the right, at its sole discretion, to modify or
              replace any of these User Terms, or change, suspend, or
              discontinue the Service or Application (including without
              limitation, the availability of any feature, database, or content)
              at any time by posting a notice on the Website or by sending you
              notice through the Service, Application or via email. Cloto may
              also impose limits on certain features and services or restrict
              your access to parts or all of the Service without notice or
              liability.
            </p>

            <h3 className="text-2xl mt-3 mb-3">Availability &amp; Updates</h3>

            <p className="mb-2">
              Cloto may alter, suspend, or discontinue the Website, App and/or
              Service at any time and for any reason or no reason, without
              notice. The Website, App and/or Service may be unavailable from
              time to time due to maintenance or malfunction of computer or
              network equipment or other reasons. Cloto may periodically add or
              update the information and materials on this Website without
              notice.
            </p>

            <h3 className="text-2xl mt-3 mb-3">Privacy Policy</h3>

            <p className="mb-2">
              Cloto may collect and processes the personal data of the visitors
              of the Website and users of the Application according to the
              Privacy Policy.
            </p>

            <h3 className="text-2xl mt-3 mb-3">General</h3>

            <p className="mb-2">
              These Terms of Use, together with any privacy policy that may be
              published on the Website, constitutes the entire agreement between
              the parties relating to the Website and Service and all related
              activities. These Terms of Use shall not be modified except in
              writing signed by both parties or by a new posting of these Terms
              of Use issued by Cloto. If any part of these Terms of Use is held
              to be unlawful, void, or unenforceable, that part shall be deemed
              severed and shall not affect the validity and enforceability of
              the remaining provisions. The failure of Cloto to exercise or
              enforce any right or provision under these Terms of Use shall not
              constitute a waiver of such right or provision. Any waiver of any
              right or provision by Cloto must be in writing and shall only
              apply to the specific instance identified in such writing. You may
              not assign the Terms of Use, or any rights or licenses granted
              hereunder, whether voluntarily, by operation of law, or otherwise
              without Cloto’s prior written consent. We may assign these Terms
              of Use without restriction. The Terms of Use shall be governed by
              the laws of the Province of Ontario without regard to choice of
              law principles.
            </p>

            <p className="mb-2">
              If you have any questions with respect to our policies concerning
              the handling of your personal information, or if you wish to
              request access to, or correction of, your personal information
              under our care and control, please contact us at:{" "}
              <a href="mailto:admin@cloto.ca">admin@cloto.ca</a>.
            </p>
            <p className="mb-2">Our Corporate Address:</p>
            <address className="ml-4 mb-8">
              Cloto Inc.
              <br />
              243 – 1885 Clements Rd
              <br />
              Pickering, <abbr title="Ontario">ON</abbr>, L1W 3V4
              <br />
            </address>
          </div>
        </div>
      </main>
      <Footer></Footer>
    </div>
  );
};

export default TermsOfService;
